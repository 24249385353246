.modal-content {
  table {
    th {
      color: #555;
    }

    &.table-bordered {
      border-color: #ddd;
      td, th {
        border-color: #ddd;
      }
    }
  }
}
