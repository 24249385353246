.icon-control {
  cursor: pointer;

  &-edit {
    &:hover {
      &:before {
        color: $blue;
      }
    }
  }

  &-confirm {
    &:hover {
      &:before {
        color: $green;
      }
    }
  }

  &-warn {
    &:hover {
      &:before {
        color: $red-dark;
      }
    }
  }

  &-danger {
    &:before {
      color: $red;
    }

    &:hover {
      &:before {
        color: $red-dark;
      }
    }
  }
}