// Extra small screen / phone
$screen-tiny-min: 480px !default;
$screen-tiny-max: ($screen-tiny-min - 1) !default;
// Small screen / tablet
$screen-small-min: 768px !default;
$screen-small-max: ($screen-small-min - 1) !default;
// Medium screen / desktop
$screen-medium-min: 992px !default;
$screen-medium-max: ($screen-medium-min - 1) !default;
// Large screen / wide desktop
$screen-large-min: 1200px !default;
$screen-large-max: ($screen-large-min - 1) !default;

// Bourbon Neat Breakpoints
$tiny-screen: new-breakpoint(min-width $screen-tiny-min 2);
$small-screen: new-breakpoint(min-width $screen-small-min 4);
$medium-screen: new-breakpoint(min-width $screen-medium-min 8);
$large-screen: new-breakpoint(min-width $screen-large-min 12);

// Generic colours
$gray-base:             #000;
$gray-darker:           lighten($gray-base, 13.5%); // #222
$gray-dark:             lighten($gray-base, 20%);   // #333
$gray:                  lighten($gray-base, 33.5%); // #555
$gray-light:            lighten($gray-base, 60%); // #999
$gray-lighter:          lighten($gray-base, 88%); // #e0e0e0
$gray-lightest:         lighten($gray-base, 93%); // #ededed
$text-muted:            $gray-light;

// Postedin colours
$black:                 #222222;
$blue:                  #429BCC;
$blue-dark:             darken($blue, 10);
$blue-darker:           darken($blue, 15);
$blue-light:            lighten($blue, 25);
$blue-lighter:          lighten($blue, 38);
$blue-lightest:         lighten($blue, 41);
$generic-website-blue:  #340dab;
$green-base:            #5DC9A9;
$green-dark:            darken($green-base, 20);
$green:                 darken($green-base, 10);
$green-light:           $green-base;
$green-pale:            lighten($green-base, 5);
$green-lighter:         lighten($green-base, 27);
$green-lightest:        #EBF4F1;
$red:                   #BE7467;
$danger-red:            darken(adjust-hue($green-light, 195), 5);
$red-dark:              $danger-red;
$red-light:             #EBCCD1;
$red-light-font:        #A94442;
$red-lighter:           #F2DEDE;
$red-lightest:          lighten(#F2DEDE, 6);
$yellow:                #EDD6B4;
$yellow-dark:           darken($yellow, 20);
$yellow-light:          lighten($yellow, 10);
$orange:                #E2AE63;
$pink:                  #FFCFCD;
$purple:                #9295CA;
$purple-light:          lighten($purple, 15);
$white:                 #f4f4f4;
$yellow-strong:         #ffdd55;

$highlight-color: #ffffcc;

// New colors. Try to use these whenever possible.
$background-light: #f0f0f0;
$background-light-hover: #E5E5E5;
$background-dark: #4A4A4A;
$background-dark-hover: #626262;
$background-orange:#dd6a58;
$background-orange-hover: #E9745F;

//For components outline
$border-color-main: #d5d5d5;
$border-color-main-hover: darken($border-color-main, 10%);

$border-color-dark:#656565;
$border-color-dark-hover: darken($border-color-dark, 10%);

// For space dividers
$border-color-light: #E5E5E5;
$border-color-light-hover: darken($border-color-light, 10%);
$border-color-focus: $blue;

$text-color-main: #4A4A4A;
$text-color-light: #666;
$text-color-muted: #9B9B9B;
$background-red: #D0021B;

// TODO: these are for backwards compatibility. Delete and update modules properly.
$border-light: $border-color-light;
$border-dark: $border-color-dark;


$font-family: "Open Sans", sans-serif;
$color: $text-color-main;
$link-color: $green-dark;
$link-blended-color: #3c3c3c;
$background: #fdfdfd;
$max-width: 1340px; // for neat
$container-max-width: 1340px;
$container-padding: 10px;
$container-small-padding: 5px;

// calendar colours
$calendarStatusColors: (
  requested-background-color: #f03e3e,
  requested-hover-background-color: #e03131,

  checking-sources-background-color: #f783ac,
  checking-sources-hover-background-color: #f06595,

  searching-background-color: #ff922b,
  searching-hover-background-color: #fd7e14,

  assigned-background-color: #ffe066,
  assigned-hover-background-color: #ffd43b,

  quality-assurance-background-color: #3bc9db,
  quality-assurance-hover-background-color: #22b8cf,

  submitted-background-color: #69db7c,
  submitted-hover-background-color: #51cf66,

  writing-background-color: #1d0000,
  writing-hover-background-color: lighten(#1d0000, 15),

  reviewing-background-color: #9775fa,
  reviewing-hover-background-color: #845ef7,

  written-background-color: #dee2e6,
  written-hover-background-color: #ced4da,

  published-background-color: #adb5bd,
  published-hover-background-color: #868e96,
);


//Padding sizes
$padding-regular: 10px;

$footer-link-color: lighten($link-color, 10);

// ** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px !default;
// ** Default background color used for all tables.
$table-bg:                      transparent !default;
$table-border-color:            #ddd !default;

// Bunch of stuff taken from bootstrap
$base-font-size:          14px !default;
$large-font-size:         ceil(($base-font-size * 1.25)) !default; // ~18px
$small-font-size:         ceil(($base-font-size * 0.85)) !default; // ~12px
$article-font-size: 16px;

$font-size-h1:            floor(($base-font-size * 2.6)) !default; // ~36px
$font-size-h2:            floor(($base-font-size * 2.15)) !default; // ~30px
$font-size-h3:            ceil(($base-font-size * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($base-font-size * 1.25)) !default; // ~18px
$font-size-h5:            ceil(($base-font-size * 1.1)) !default; // ~15px
$font-size-h6:            ceil(($base-font-size * 0.85)) !default; // ~12px

// ** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default;
// ** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($base-font-size * $line-height-base)) !default;

// ** By default, this inherits from the `<body>`.
$headings-font-family:    'Open Sans', sans-serif !default;
$headings-font-weight:    700 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;

$error-color:              #a94442 !default;
$error-background-color:   #f2dede !default;
$error-border-color:       darken(adjust-hue($error-background-color, -10), 5%) !default;

// login/register
$prominent-background: #fff;

// admin
$admin-red: adjust-hue($green-light, 195); // red based on the hue of the green

// Some other units
$border-radius: 3px;
$transition-delay: .2s;

// z-indexes
$fixed-depth: 10;
$mask-depth: 50;
$mask-inner-depth: 60;
$dropdown-menu-depth: 100;
$maintenance-depth: 1090;

$planningStageColor: #429BCC;
$contentStageColor: #5DC9A9;
$doneStageColor: #309074;
$closedStageColor: #4c1b0e;
$concludedStageColor: #4c1b0e;

$pendingStatusColor: darken($gray-light, 5);
$suggestedStatusColor: #24b8d2;
$requestedStatusColor: darken(#7C98A2, 10);
$searchingStatusColor: lighten(#49C9E0, 5);
$assignedStatusColor: #24b8d2;
$qualityAssuranceStatusColor: darken(#24b8d2, 8);
$submittedStatusColor: darken(#1c8ea1, 5);
$reviewingStatusColor: darken(#1c8ea1, 10);
$writingStatusColor: darken(#20a5bc, 1);
$writtenStatusColor: #279178;
$publishedStatusColor: #155143;
$canceledStatusColor: #E2AE63;
$closedStatusColor: rgb(168, 130, 77);
$rejectedStatusColor: #C4260F;
$expiredStatusColor: #540B00;

$info-message-background: #D9EDF7;
$info-message-color: #31708F;
$info-message-border: #BCE8F1;

$success-message-background: #dFF0D8;
$success-message-color: #3C763D;
$success-message-border: #D6E9C6;

// libs
$ember-power-select-default-border-radius: 0;
$ember-power-select-line-height: 2;
$ember-power-select-number-of-visible-options: 10;
