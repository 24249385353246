/***********************************/
/* custom widgets classes (email widget, charts, etc). This file can be removed. */
/***********************************/

/***********************************/
/**          EVENTS               **/
/**********************************/

.external-event {
  margin: 15px 0;
  padding: 5px 10px;
  color: #fff;
  cursor: pointer;
  border-radius: $border-radius-base;
}


/***********************************/
/**          FEED                 **/
/**********************************/

.feed{
  height: 100%;
  //overflow-y: auto;
  position: relative;

  .wrapper{
    position: relative;
  }

  .vertical-line{
    height: 100%;
    width: 2px;
    background: $feed-item-line-color;
    position: absolute;
    left: 13px;
  }
}

.feed-item{
  @include clearfix;
  padding-right: 6px;
  .icon{
    position: relative;
    z-index: 1;
    background: $feed-item-bg;
    text-align: center;
    color: $white;
    width: 28px;
    height: 28px;
    line-height: 28px;
    vertical-align: middle;
    border-radius: 20px;
    font-size: 16px;

    img{
      position: relative;
      top: -2px;
      width: 26px;
      height: 26px;
    }
  }
}

.feed-item + .feed-item{
  margin-top: 10px;
}

.feed-item-body{
  padding-top: 3px;
  margin-left: 40px;
  vertical-align: bottom;

  .time{
    font-size: $font-size-small;
    line-height: 1.5em;
    color: $text-muted;
  }
}

//jQuery DataTables

table.table thead .sorting,
table.table thead .sorting_asc,
table.table thead .sorting_desc,
table.table thead .sorting_asc_disabled,
table.table thead .sorting_desc_disabled {
  cursor: pointer;
  *cursor: hand;
}

table.table thead .sorting { background: url('#{$libs-compiled-folder}/datatables/media/images/sort_both.png') no-repeat center right; }
table.table thead .sorting_asc { background: url('#{$libs-compiled-folder}/datatables/media/images/sort_asc.png') no-repeat center right; }
table.table thead .sorting_desc { background: url('#{$libs-compiled-folder}/datatables/media/images/sort_desc.png') no-repeat center right; }

table.table thead .sorting_asc_disabled { background: url('#{$libs-compiled-folder}/datatables/media/images/sort_asc_disabled.png') no-repeat center right; }
table.table thead .sorting_desc_disabled { background: url('#{$libs-compiled-folder}/datatables/media/images/sort_desc_disabled.png') no-repeat center right; }

.per-page-selector label{
  vertical-align: middle;
  line-height: 26px;
}

.table-top-control{
  margin-bottom: 5px;
}

.table-bottom-control{
  margin-top: 10px;
  .pagination{
    margin: 0;
  }
}


/***********************************/
/**          FILEUPLOAD          **/
/**********************************/

.fileupload-progress.fade{

  &.in{
    .progress{
      margin-top: 20px;
      margin-bottom: 5px;
    }

    .progress-extended{
      margin-bottom: 10px;
    }
  }

  .progress{
    margin: 0;
  }
}

.template-upload video{
  max-width: 200px;
}

.dropzone{
  border: 2px dashed $dropzone-color;
  margin-top: 10px;
  font-size: 40px;
  color: $dropzone-color;
  line-height: 120px;
  padding-left: 7%;
  vertical-align: middle;
  border-radius: $border-radius-base;

  @include clearfix;

  i{
    margin-top: 20px;
    padding-right: 10%;
  }
}

/***********************************/
/**         Search Result         **/
/***********************************/

.search-result-categories{
  > li > a {
    color: $text-color;
    background-color: transparent;
    font-weight: $font-weight-normal;
    &:hover{
      color: $white;

    }

    > .glyphicon{
      margin-right: 5px;
    }

    > .badge{
      float: right;
    }
  }
}

.search-results-count{
  margin-top: 10px;
}

.search-result-item{
  padding: 20px;
  background-color: $widget-bg;
  border-radius: $border-radius-base;
  @include clearfix();

  .image-link{
    display: block;
    overflow: hidden;
    border-top-left-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      margin: -20px 0 -20px -20px;
      float: left;
      width: 200px;
    }
    @media (max-width: $screen-xs-max) {
      max-height: 200px;
    }
  }

  .image{
    max-width: 100%;
  }

  .info{
    margin-top: 2px;
    font-size: $font-size-small;
    color: $text-muted;
  }

  .description{
    color: $text-muted;
  }

  + .search-result-item{
    margin-top: 20px;
  }
}

.search-result-item-body{
  @media (min-width: $screen-sm-min) {
    margin-left: 200px;
  }
}

.search-result-item-heading{
  font-weight: $font-weight-normal;

  > a {
    color: $widget-header-color;
  }

  @media (min-width: $screen-sm-min) {
    margin: 0;
  }
}

.pagination-search-result{
  > li > a,
  > li > span{
    color: $text-color;

    &:hover,
    &:focus{
      background-color: rgba($widget-bg, .25);
      color: $white;
    }
  }

  > .disabled > span,
  > .disabled > span:hover,
  > .disabled > span:focus,
  > .disabled > a,
  > .disabled > a:hover,
  > .disabled > a:focus{
    color: #ddd;
  }

  > .active > a,
  > .active > a:hover,
  > .active > a:focus,
  > .active > span,
  > .active > span:hover,
  > .active > span:focus{
    background-color: rgba($widget-bg, .25);
    color: $white;
  }
}

/***********************************/
/**          ERROR PAGE           **/
/***********************************/

//.error-page{
//  background-color: $gray-semi-lighter;
//}

.error-container{
  padding-top: 5%;
  text-align: center;

  > .btn{
    padding-left: 35px;
    padding-right: 35px;
  }
}

.error-code{
  font-size: 80px;
  font-weight: $font-weight-thin;
  color: $white;
  @media (min-width: $screen-sm-min) {
    font-size: 180px;
  }
}

.error-info{
  font-size: 20px;
  color: $gray-dark;
}

.error-help{
  font-size: 14px;
}

.error-page .page-footer {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  width: 100%;
  font-size: $font-size-small;
  color: $text-muted;
  text-align: center;
}

/***********************************/
/**            INVOICE            **/
/***********************************/

.invoice-logo{
  width: 253px;
  height: 70px;
}

.invoice-number{
  font-size: $font-size-base * 1.5;
  margin-top: 15px;
}

.invoice-number-info{
  color: $text-muted;
  font-size: $font-size-larger;
}

.invoice-info{
  .details-title{
    color: $text-muted;
    margin: 0;
  }
}

.invoice-actions{
  margin-top: $line-height-computed;
}

.client-details{
  text-align: right;
}

/***********************************/
/**             Inbox             **/
/***********************************/


.nav-email-folders{
  > li > a {
    color: $gray-lighter;
    font-weight: $font-weight-normal;
    background-color: transparent;
    &:hover{
      background-color: rgba($widget-bg-common, .25);
      color: $text-color;
    }

    > .glyphicon{
      margin-right: 5px;
    }

    > .fa-circle{
      margin-top: 3px;
    }

    > .badge{
      background-color: $brand-danger;
      font-weight: $font-weight-semi-bold;
    }
  }

  > li.active > a{
    &,
    &:hover,
    &:focus {
      background-color: rgba($widget-bg-common, .25);
      color: $text-color;
      font-weight: $font-weight-semi-bold;

      > .badge{
        color: $gray-dark
      }
    }
  }
}

.widget-email-count{
  display: inline-block;
  margin: 0;
  font-size: $font-size-small;
  color: $text-muted;
  line-height: 29px;

  + .widget-email-pagination{
    margin-left: 10px;
    border-left: 1px solid $gray-semi-lighter;
    padding-left: 15px;
    border-radius: 0;
    vertical-align: -9px;
  }
}

.widget-email-pagination{
  margin: 0;
}

.table-emails{
  margin-bottom: 0;

  .name,
  .subject,
  .date{
    cursor: pointer;
  }

  .date{
    text-align: right;
    min-width: 65px;
  }

  .unread{
    font-weight: $font-weight-semi-bold;
    color: $widget-color;
  }

  .favorite{
    text-align: center;
  }

  .starred{
    color: $text-muted;
    cursor: pointer;
    @include transition(color .2s);
    &:hover{
      color: $text-color;
    }

    .fa-star{
      color: $brand-warning;
    }
  }

  .widget-table-overflow & td,th{
    &:first-child{
      padding-left: $widget-padding-horizontal;
    }

    &:last-child{
      padding-right: $widget-padding-horizontal;
    }
  }
}

.compose-view{
  margin: $widget-padding-vertical $widget-padding-horizontal;
}

.email-view{
  margin: $widget-padding-vertical $widget-padding-horizontal;
  hr{
    margin: 5px 0;
  }

  .email-body{
    margin-top: $line-height-computed;
  }
}

.email-details img{
  width: 30px;
  height: 30px;
  float: left;
}

.email-details-content{
  @include clearfix();

  .email{
    color: $text-muted;
    font-size: $font-size-mini;
  }

  .receiver{
    display: block;
    color: $text-muted;
    margin-top: -6px;
  }

  .email-date{
    margin-right: 10px;
    line-height: 24px;
    vertical-align: middle;
  }
}

.email-attachments{
  .attachment{
    img{
      display: block;
    }

    .title{
      margin: 0;
      font-weight: bold;
    }

  }
}

/***********************************/
/**            LANDING            **/
/***********************************/

.landing{
  padding-bottom: 40px;
  .page-header{
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-menu > li{
    > a{
      line-height: 40px;
      padding: 0 10px;
      width: auto;
      font-size: $font-size-large;
    }
  }

  .navbar-buttons{
    margin-right: 20px;
    > li{
      float: left;
      > a{
        margin: 7px 10px;
      }
    }
  }

  .logo{
    position: static;
  }

  .content{
    padding: 25px 0;
  }

  .lead{
    font-weight: normal;
  }

  .btn{
    font-weight: normal;

    + .btn{
      margin-left: 10px;
    }
  }

  .widget p{
    font-size: $font-size-larger;
  }

  .widget-about{
    margin-top: 60px;
    padding: 20px;

    h2, h4{
      margin-top: 0;
      font-weight: bold;
    }

    .preview{
      margin: 40px 0;
    }

    .btn{
      color: white;
    }
  }

  .img-about{
    margin-top: 8px;
  }

  .features{
    margin-top: 60px;
  }

  .feature{
    text-align: center;

    .icon{
      display: inline-block;
      height: 60px;
      width: 60px;
      font-size: 40px;
      line-height: 60px;
      vertical-align: middle;
      border-radius: 50%;
      > i{
        color: #ffffff;
      }
    }
  }

  .thanks{
    margin-top: 60px;
  }

  .back-to-top{
    color: $text-color
  }
}

.widget-white{
  background: $white;
  color: $gray;
  @include box-shadow(0 -1px 1px $shadow-color, 0 1px 1px $shadow-color);

  *{
    color: $gray;
  }
}

.carousel-landing{
  height: 450px;
  overflow: hidden;

  img{
    max-width: 100%;
  }

  h1{
    font-size: 45px;
    line-height: 1.1;
  }

  .item > .row > *:first-child{
    text-align: right;
  }

  .slide-one-header{
    margin-top: 80px;
  }

  .slide-two-header{
    margin-bottom: 40px;
  }

  .slide-three-header{
    margin-top: 100px;
    margin-bottom: 20px;
  }

  .btn-version-preview{
    margin-top: 20px;
    @media (min-width: 480px) and (max-width: 768px) {
      display: none;
    }
  }
}

.carousel-fade{
  .carousel-inner{
    > .item{
      @include transition(opacity .2s ease-in-out);
    }

    > .next,
    > .prev {
      left: auto;
    }
    > .next.left,
    > .prev.right {
      opacity: 1;
      left: auto;
    }

    > .active.left,
    > .active.right,
    > .next,
    > .prev{
      opacity: 0;
      left: auto;
    }
  }
}

.carousel-control-landing {
  opacity: 1;
  width: 2%;
  //font-size: 50px;
  text-shadow: 0 0 8px rgba(255,255,255, .6);

  @media screen and (min-width: $screen-tablet) {
    .icon-prev,
    .icon-next {
      font-size: 50px;
    }
  }


  &.left {
    background: none;
  }
  &.right {
    background: none;
  }
}


/***********************/
/*    Notifications    */
/***********************/

.location-selector {
  width: 100%;
  height: 220px;
  border: 1px dashed $location-selector-border-color;
  background-color: $location-selector-bg;
  position: relative; }
.location-selector .bit {
  background-color: darken($location-selector-bg, 10%);
  @include transition(background-color .15s ease-in-out);
  cursor: pointer;
  position: absolute; }
.location-selector .bit:hover {
  background-color: darken($location-selector-bg, 15%);; }
.location-selector .bit.top, .location-selector .bit.bottom {
  height: 25%;
  width: 40%;
  margin: 0 30%; }
.location-selector .bit.top {
  top: 0; }
.location-selector .bit.bottom {
  bottom: 0; }
.location-selector .bit.right, .location-selector .bit.left {
  height: 20%;
  width: 20%;
  margin-left: 0;
  margin-right: 0; }
.location-selector .bit.right {
  right: 0; }
.location-selector .bit.left {
  left: 0; }


/***********************************/
/**          BOXES               **/
/**********************************/


/*boxes*/
.box{
  border-radius: $border-radius-base;

  padding: 5px;
  text-align: center;
  margin-bottom: $widget-mb;
  background: $widget-bg;
  color: $widget-color;

  .description{
    font-weight: normal;
    text-align: center;
    margin: 10px -5px;
  }

  .icon, .big-text{
    margin-top: 15px;
    font-size: 36px;
    line-height: 36px;
    height: 36px;
    font-weight: bold;
  }
}

/***********************************/
/**          CHARTS               **/
/**********************************/


.large .pie-chart{
  height: 212px;
}

.xlarge .pie-chart{
  height: 298px;
}

.pie-chart{
  position: relative;

  .total{
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
  }

  .visits{
    font-weight: $font-weight-base;
    color: $widget-header-color;
    font-size: $font-size-large;
  }
}

.pie-chart-footer{

  .controls{
    margin-top: 16px;
    width: 100%;
  }

  .control{
    @include box-sizing(content-box);
    background: $bg-addition;
    text-align: center;
    font-weight: normal;
    padding: 3px 2%;
    color: $widget-header-color;
    width: 20.625%;
    cursor: pointer;
    @include user-select(none);

    margin-left: 0.5%;

    float:left;

    &:first-child{
      border-bottom-left-radius: $border-radius-base;
      margin-left: 0;
    }

    &:last-child{
      border-bottom-right-radius: $border-radius-base;
    }

    &.disabled{
      border-top: 3px solid transparent !important;
    }

    .key{
      font-size: $font-size-small;
      font-weight: normal;
      margin: 0;
    }

    .value{
      font-size: $font-size-large;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}

.visits-chart{
  height: 200px;
}

.visits-info{
  margin: 12px 0 0;
  text-align: center;
  padding-bottom: 0;

  .key{
    color: $widget-header-color
  }

  .value{
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: $font-size-large;
  }
}

/* disable bootstrap 3 border-box for tooltips */
.jqstooltip{
  &, *{
    @include box-sizing(content-box);
  }
}

/***********************************/
/**          NEWS LIST           **/
/**********************************/
/**
 * Legacy .users-list. Will be removed in next release.
 */

.news-list,
.users-list{

  &.news-list-no-hover li:hover{
    background: none;
    cursor: default;
  }

  &.stretchable > li{
    height: auto;
  }

  li{
    @include box-sizing(content-box);
    border-top: 1px solid $bg-addition;
    padding: 12px;
    margin: 0 (-$widget-padding-horizontal);
    cursor: pointer;
    height: 50px;
    @include transition(background-color .2s ease-out);

    &:hover{
      background: $bg-addition;
    }

    &:last-child{
      margin-bottom: -$widget-padding-vertical;
    }
  }

  img,
  .icon{
    float: left;
    height: 50px;
    width: 50px;
  }

  .icon{
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 32px;
  }

  .news-item-info{
    margin-left: 62px; /*50 + 12px padding*/
  }

  .name, .time, .position{
    line-height: 16px;
  }

  h4.name{
    text-transform: none;
  }

  .name{
    margin: 0;
    text-transform: uppercase;

    a{
      text-decoration: none;
      &:hover{
        color: $link-color;
      }
    }
  }

  p{
    margin-top: 5px;
    font-size: 90%;
    line-height: 1.4;
  }

  .time{
    font-size: $font-size-small;
    color: $text-muted;
  }

  .options{
    margin-top: 5px;
  }

  .comment{
    font-size: $font-size-small;
    color: $widget-color;
    line-height: 1.3em;
  }
}

.users-list-group{
  img{
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 10px;
  }

  .fa-circle{
    margin-top: 14px;
  }
}


/***********************************/
/**          LOGIN                **/
/**********************************/

.single-widget-container {
  left: 0;
  top: 50%;
  margin-top: -(385px / 2);
  position: absolute;
  width: 100%;

  .widget {
    margin: 0 auto;
  }
}

.login-widget, .registration-widget {
  width: 350px;
  padding-bottom: 0;

  header {
    margin: 15px 0 25px 0;
  }

  .form-control{
    font-size: $font-size-base;
  }

  .input-group-addon{
    padding: 8px 7px;
    i{
      &, &:before, &:after{
        width: 20px;
        margin: 0;
      }
    }
  }

  .form-actions {
    margin: 0 (-$widget-padding-horizontal);
    padding: 20px 15px 0 15px;

    .small-circle {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      background: rgba(0,0,0,.2);

      i{
        position: relative;
        left: 1px;
      }
    }
    .forgot {
      display: block;
      text-align: center;
      color: $widget-header-color;
      padding: 15px 0;
    }
  }

  footer{
    margin: 0 (-$widget-padding-horizontal);
    border-bottom-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
    overflow: hidden;
    position: static;
  }

  .facebook-login {
    @include box-sizing(content-box);
    height: 30px;
    background: $blue;
    text-align: center;
    padding-top: 10px;

    a {
      color: $white;
      display: block;
      text-shadow: none;
      text-decoration: none;
    }

  }
}

/***********************************/
/**          CHAT                 **/
/**********************************/

.chat-messages{
  padding-right: 15px; //some space for scroll
}

.chat-footer{
  height: 40px;
  @include box-sizing(border-box);
  padding-top: 10px;
  input{
    margin-bottom: 0;
  }
}

.chat-message{
  @include clearfix;
  .icon{
    position: relative;
    z-index: 1;
    text-align: center;
    width: 53px;
    height: 53px;
    line-height: 53px;
    vertical-align: middle;
    border-radius: 53px;

    img{
      position: relative;
      top: -2px;
      width: 50px;
      height: 50px;
    }
  }

  .time{
    font-size: $font-size-small;
    line-height: 1.5em;
    text-align: center;
    color: $text-muted;
  }
}

.chat-message + .chat-message{
  margin-top: 10px;
}

.chat-message-body{
  margin-left: 63px;
  padding: 8px 10px;
  background: $bg-addition;
  position: relative;
  border-left: 2px solid $text-muted;

  border-radius: $border-radius-base;

  &.on-left{
    margin-left: 0;
    margin-right: 63px;
    border-left: none;
    border-right: 2px solid $text-bold;

    .arrow{
      left: auto;
      right: -7px;

      border-right: none;
      border-left: 5px solid $text-bold;
    }

    .sender, .text{
      text-align: right;
    }
  }

  .arrow{
    display: block;
    position: absolute;
    top: 21px;
    left: -7px;
    width: 0;
    height: 0;

    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid $text-bold;
  }
}

/***********************************/
/**          STATS-ACTIVITY       **/
/**********************************/

.overall-stats{

  li{
    margin-top: 12px;
  }

  .key{
    line-height: 18px;
    margin-left: 5px;
  }

  .icon{
    text-align: center;
  }

  .value{
    text-align: right;
  }
}


.server-stats{
  margin-top: -3px;
  margin-bottom: 2px;
  li{
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }

  .progress{
    margin-bottom: 0;
  }
}

/***********************************/
/**           ICON LIST           **/
/***********************************/

.icon-list{
  margin-top: $line-height-computed;
}

.icon-list-item{
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  > a{
    color: $widget-color;
    text-decoration: none;
  }


  .glyphicon,
  .fa{
    width: 32px;
    margin-right: 10px;
  }

  &:hover{
    .glyphicon,
    .fa{
      font-size: 28px;
    }

    .fa{
      vertical-align: -5px;
    }

    .glyphicon{
      vertical-align: -6px;
    }
  }
}

/***********************************/
/**      LIST GROUP SORTABLE      **/
/***********************************/

.list-group-sortable{
  > .list-group-item{
    margin-bottom: 0;
    border-radius: $border-radius-base;

    + .list-group-item{
      margin-top: $line-height-computed/2;
    }
  }

  > .list-group-item-placeholder{
    border: 1px dashed $gray-light;
    background-color: rgba($widget-bg-common, .25);
  }

  &:last-of-type > .list-group-item:last-child{
    border-bottom: 1px solid $list-group-border;
  }
}

/**
 * Nestable
 */

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  color: $widget-color;
  text-decoration: none;
  background: $widget-bg;
  border-radius: $border-radius-base;

  @include box-sizing(border-box);
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
  color: $widget-color;
}

.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action="collapse"]:before {
  content: '-';
}

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: rgba($widget-bg-common, .2);
  border: 1px dashed rgba(white, .6);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed rgba(white, .6);
  border-radius: $border-radius-base;
  min-height: 100px;
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
  background-image: -moz-linear-gradient(45deg, rgba(255,255,255,.75) 25%, transparent 25%, transparent 75%, rgba(255,255,255,.75) 75%, rgba(255,255,255,.75)),-moz-linear-gradient(45deg, rgba(255,255,255,.75) 25%, transparent 25%, transparent 75%, rgba(255,255,255,.75) 75%, rgba(255,255,255,.75));
  background-image: linear-gradient(45deg, rgba(255,255,255,.75) 25%,rgba(0, 0, 0, 0) 25%,rgba(0, 0, 0, 0) 75%,rgba(255,255,255,.75) 75%,rgba(255,255,255,.75)),linear-gradient(45deg, rgba(255,255,255,.75) 25%,rgba(0, 0, 0, 0) 25%,rgba(0, 0, 0, 0) 75%,rgba(255,255,255,.75) 75%,rgba(255,255,255,.75));
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
}

/**
 * Nestable Extras
 */

.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

@media only screen and (min-width: 700px) {
  .dd + .dd {
    margin-left: 2%;
  }

}

.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}

/**
 * Nestable Draggable Handles
 */

.dd3-content {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px 5px 40px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: linear-gradient(top, #fafafa 0%, #eee 100%);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd3-content:hover {
  color: #2ea8e5;
  background: #fff;
}

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}

.dd3-item > button {
  margin-left: 30px;
}

.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 30px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #aaa;
  background: #ddd;
  background: -webkit-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: linear-gradient(top, #ddd 0%, #bbb 100%);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dd3-handle:before {
  content: '≡';
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
}

.dd3-handle:hover {
  background: #ddd;
}



/***********************************/
/**    RATING STATIC COMPONENT    **/
/***********************************/
.rating-static {
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: left;
  display: inline-block;

  & > span {
    display: inline-block;
    position: relative;
    width: 1em;
    cursor: default;
    color: #BBB;
  }

  & > span.active, & > span.active ~ span {
    color: transparent;
  }

  & > span.active:before, & > span.active ~ span:before {
    position: absolute;
    left: 0;
    color: white;
  }

  & > span.active:before, & > span.active ~ span:before {
    content: "\2605";
  }

}
