@import "font";

@import "variables";
@import "mixins";

// essential libs
//
@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
//@import "../../../bower_components/font-awesome/scss/font-awesome";
//@import "../../../bower_components/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.scss";
//@import "../../../bower_components/animate.css/animate";

@import "override-libs";
@import "override-bootstrap";

//
// END essential libs


// custom libs. can be removed
//

// @import "CSS:../../../bower_components/nvd3/build/nv.d3";
// @import "CSS:../../../bower_components/bootstrap-select/dist/css/bootstrap-select";
// @import "CSS:../../../bower_components/bootstrap3-wysihtml5-bower/dist/bootstrap3-wysihtml5";
// @import "CSS:../../../bower_components/select2/select2";
// @import "../../../bower_components/select2-bootstrap-css/lib/select2-bootstrap";
// @import "CSS:../../../bower_components/switchery/dist/switchery";
// @import "CSS:../../../bower_components/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker";
// @import "CSS:../../../bower_components/mjolnic-bootstrap-colorpicker/dist/css/bootstrap-colorpicker";
// @import "CSS:../../../bower_components/rickshaw/rickshaw";
// @import "CSS:../../../bower_components/messenger/build/css/messenger";
// @import "CSS:../../../bower_components/messenger/build/css/messenger-spinner";
// //@import "CSS:../../../bower_components/messenger/build/css/messenger-theme-air"; see _custom-libs-override. the whole file is included there as we don't want to use Raleway font
// @import "override-messenger";
// @import "CSS:../../../bower_components/fullcalendar/dist/fullcalendar";
// @import "CSS:../../../bower_components/jqvmap/dist/jqvmap";
// @import "../../../bower_components/magnific-popup/src/css/main";
// @import "CSS:../../../bower_components/blueimp-file-upload/css/jquery.fileupload";

@import "override-custom-libs";

//
// END custom libs

@import '../../../bower_components/pikaday/scss/pikaday';

@import "general";

@import "utils";

@import "base";

@import "responsive";

@import "widgets";

@import "dropdown";

@import "modal";

@import "print";

@import "shame";

@import "print";

@import "controls";

@import '../variables';
@import '../modules/icon';

.widget:not(.widget-tabs) .tab-content {
  overflow: auto;
}
