// .btn-group shouldn't wrap. Restricted to tables for now.
table .btn-group {
  display: flex;
}

// Superscript style for FontAwesome icons
.fa {
  &-super {
    vertical-align: super;
    font-size: .5em;
  }
}

// No outline for custom radio inputs
.radio {
  label {
    &::before {
      outline: none !important;
    }
  }
}

// Margins
.m {
  @for $i from 1 through 4 {
    //mt = margin-top
    &t {
      &#{$i}0 {
        padding-top: 10px * $i;
      }
    }

    //mb = margin-bottom
    &b {
      &#{$i}0 {
        padding-bottom: 10px * $i;
      }
    }
  }
}
