// Fix for ember-bootstrap's dropdown menu
 .btn-group.open {
  z-index: 9999 !important;
}

.dropdown-menu > li > a {
  &:hover {
    .fa {
      color: white;
    }
  }
}
