//some basic definitions
//html-elements only. no classes

@mixin background-radial-gradient($figure, $type, $position_x, $position_y, $color_1, $color_2, $color_3){
  //there is a built-in compass radial-gradient function, but it does not provide enough compatibility, so need to imitate it
  //it also has an option $experimental-support-for-svg which should generate correct svg for IE, but it works incorrect, so
  //fixing it
  //issue id - https://github.com/chriseppstein/compass/issues/1225
  background-color: $color_1;
//final w3c
  background-image: radial-gradient(
      $type $figure at $position_x $position_y,
      $color_1,
      $color_2,
      $color_3
  );
  //webkit-specific
  background-image: -webkit-radial-gradient(
      $position_x $position_y, $type $figure,
      $color_1,
      $color_2,
      $color_3
  );

  //moz-specific
  background-image: -moz-radial-gradient(
      $position_x $position_y, $type $figure,
      $color_1,
      $color_2,
      $color_3
  );
}

html{
  min-height: 100%;
}

body{
  -webkit-font-smoothing:antialiased;
  font-weight: $font-weight-base;
  padding-bottom: 100px;
  // @include background-radial-gradient(ellipse, farthest-side, 10%, 0,
  //   rgba(74, 121, 147, 0.89),
  //   rgba(204, 204, 204, 0.85) 80%,
  //   rgba(185, 135, 131, 0.87) 120%
  // );
  //&.background-dark{
    @include background-radial-gradient(ellipse, farthest-side, 10%, 0,
      rgba(102, 105, 104, 0.90),
      rgba(93, 112, 119, 0.89),
      rgba(160, 174, 150, 0.90)
    );
  //}

  background-attachment: fixed, fixed;
}

abbr[title] {
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6{
  font-weight: $font-weight-base;

  small{
    color: $text-muted;
    font-weight: $font-weight-base;
  }
}

input[type=search], input.search {
  padding-left: 26px;
  background-color: $input-bg;
  background:url(../img/search.png) 5px 6px no-repeat $input-bg;

  &.input-transparent{
    border: none;
    color: $widget-color;
    background: url(../img/search-white.png) 5px 5px no-repeat $widget-bg;
    @include placeholder(rgba(#eee, .76));
  }
}

ul ul,
ol ul{
  list-style: none;
}

ul, ol{
  list-style: none;
  padding: 0;
}

ul.default-list, .default-list ul {
   list-style-type: disc;
   list-style-position: inside;
}
ol.default-list, .default-list ol {
   list-style-type: decimal;
   list-style-position: inside;
}
ul.default-list ul, ol.default-list ul {
   list-style-type: circle;
   list-style-position: inside;
   margin-left: 15px;
}
ol.default-list ol, ul.default-list ol {
   list-style-type: lower-latin;
   list-style-position: inside;
   margin-left: 15px;
}


//setting default iframe behaviour to border box
//so it behaves normally within .control-row
iframe{
  @include box-sizing(border-box);
}

//opera is buggy? no chrome is buggy! really strange fix
fieldset{
  width: 100%;
  //Thanks to johan@holmweb.se for that fix
  min-width: 0;
}

form{
  margin-bottom: 0;
}

a{
  @include transition(color .2s);
  cursor: pointer;
}

.btn{
  @include transition(background-color .2s);
}

svg {
  height: 100%;
  width: 100%;
  display: block;
}

.right {
  float: right;
}
